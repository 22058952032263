<template>
  <div>
    <div class="navMenu-main">
      <div id="menu" class="gn-icon-menu"><span></span></div>
    </div>
    <Header :instanceInfo="instanceInfo" />
    <div class="main">
      <div class="container">
        <div class="row">
          <SideMenu :instanceInfo="instanceInfo" />
          <div class="col-md-9">
            <h2 class="pink">Settings</h2>
            <p></p>
            <div class="shadow padded">

              <form @submit.prevent="submitPassword">
                <h4>Password</h4>
                <ul class="password-list">
                  <li>
                    Current password:<br>
                    <input v-model="oldPassword" name="password" type="password">
                  </li>
                  <li>
                    New password:<br>
                    <input v-model="newPassword" name="password_new" type="password">
                  </li>
                  <li>
                    New password (again):<br>
                    <input v-model="confirmPassword" name="password_new2" type="password">
                  </li>
                </ul>
                <input class="btn" type="submit" value="Save">
                <div v-if="changeSuccessful" class="alert alert-success alert-dismissible">
                  <button @click="changeSuccessful = false" type="button" class="close" data-dismiss="alert"
                    aria-label="Close"><span aria-hidden="true">×</span></button>
                  <p>Your password was successfully changed</p>
                </div>
                <div v-if="passwordErrorMessage" class="alert alert-danger alert-dismissible">
                  <button @click="passwordErrorMessage = null" type="button" class="close" data-dismiss="alert"
                    aria-label="Close"><span aria-hidden="true">×</span></button>
                  <p>{{ passwordErrorMessage }}</p>
                </div>
              </form>
              <hr noshade="">

              <template v-if="isPremium">
                <form @submit.prevent="submitCancel">
                  <h4 data-toggle="collapse" data-target="#subCancel" style="cursor:pointer;" class="collapsed"
                    aria-expanded="false">
                    My subscription »
                  </h4>
                  <div id="subCancel" class="collapse" aria-expanded="false" style="height: 0px;">
                    <p>
                      Click on the button below to cancel your subscription.
                    </p>
                    <input class="btn" id="btnSubCancel" type="submit" value="Cancel my subscription">
                    <div v-if="cancelSuccessful" class="alert alert-success alert-dismissible">
                      <button @click="cancelSuccessful = false" type="button" class="close" data-dismiss="alert"
                        aria-label="Close"><span aria-hidden="true">×</span></button>
                      <p>Your subscription was cancelled. You will be automatically logged out</p>
                    </div>
                    <div v-if="cancelErrorMessage" class="alert alert-danger alert-dismissible">
                      <button @click="cancelErrorMessage = null" type="button" class="close" data-dismiss="alert"
                        aria-label="Close"><span aria-hidden="true">×</span></button>
                      <p>{{ cancelErrorMessage }}</p>
                    </div>
                  </div>

                </form>
                <hr noshade="">
              </template>

              <form @submit.prevent="submitDelete">
                <h4 data-toggle="collapse" data-target="#delAcc" style="cursor:pointer;">Deactivate my account »</h4>
                <div id="delAcc" class="collapse">
                  <p>By clicking the button below, I agree that my complete message history and account will be erased.
                  </p>
                  <input class="btn" id="btnDeactivate" type="submit" value="Deactivate my account">
                  <div v-if="deleteSuccessful" class="alert alert-success alert-dismissible">
                    <button @click="deleteSuccessful = false" type="button" class="close" data-dismiss="alert"
                      aria-label="Close"><span aria-hidden="true">×</span></button>
                    <p>Your account was deactivated. You will be automatically logged out</p>
                  </div>
                  <div v-if="deleteErrorMessage" class="alert alert-danger alert-dismissible">
                    <button @click="deleteErrorMessage = null" type="button" class="close" data-dismiss="alert"
                      aria-label="Close"><span aria-hidden="true">×</span></button>
                    <p>{{ deleteErrorMessage }}</p>
                  </div>
                </div>
              </form>

              <hr noshade="">

              <h4 data-toggle="collapse" data-target="#requestData" style="cursor:pointer;">Request my data »</h4>
              <div id="requestData" class="collapse">
                <p>Write an email to <a :href="'mailto:' + instanceInfo?.supportEmail">
                    {{ instanceInfo?.supportEmail }}
                  </a> to request a copy of the data we hold on you.</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs"/>
  </div>
</template>

<script>
import { ref } from 'vue';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';
import SideMenu from '@/app/components/SideMenu';
import { useUser } from '@/app/use/user';
import { changePassword, cancelSubscription } from '@/app/api/user';


export default {
  name: 'Members',
  components: { Header, Footer, SideMenu },
  props: {
    instanceInfo: {
      type: Object,
      required: false,
    },
    companyName: {
      type: String,
      required: false,
    },
  },
  setup() {

    const oldPassword = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');
    const passwordErrorMessage = ref(null);
    const changeSuccessful = ref(false);

    const submitPassword = () => {
      passwordErrorMessage.value = null;
      if (oldPassword.value.length < 8) {
        passwordErrorMessage.value = 'Old password must be at least 8 characters';
        return;
      }
      if (newPassword.value.length < 8) {
        passwordErrorMessage.value = 'New password must be at least 8 characters';
        return;
      }
      if (confirmPassword.value !== newPassword.value) {
        passwordErrorMessage.value = 'New password must be confirmed in [New password (again)] field';
        return;
      }

      const changeRequest = {
        oldPassword: oldPassword.value,
        newPassword: newPassword.value,
      };
      console.log(changeRequest);
      changePassword(changeRequest)
        .then(response => {
          changeSuccessful.value = true;
          return response;
        })
        .catch(() => {
          changeSuccessful.value = false;
          passwordErrorMessage.value = "Due to technical problem your password can't be changed. Try again or contact our support to help you setting up new password.";
        });
    };

    const { logout, isPremium } = useUser();
    const cancelErrorMessage = ref(null);
    const cancelSuccessful = ref(false);
    const cancelError = "Due to technical problem your subscription can't be cancelled. Try again or contact our support to cancel your subscription.";

    const submitCancel = () => {
      cancelSubscription()
        .then(response => {
          if (response.status === 'SUCCESSFUL') {
            cancelSuccessful.value = true;
            cancelErrorMessage.value = null;
            setTimeout(function () {
              logout();
            }, 3000);
          } else {
            cancelSuccessful.value = false;
            cancelErrorMessage.value = cancelError;
          }
        })
        .catch(() => {
          cancelSuccessful.value = false;
          cancelErrorMessage.value = cancelError;
        });
    };

    const deleteErrorMessage = ref(null);
    const deleteSuccessful = ref(false);
    const deleteError = "Due to technical problem your account can't be deactivated. Try again or contact our support to deactivate your account.";

    const submitDelete = () => {
      cancelSubscription()
        .then(response => {
          if (response.status === 'SUCCESSFUL') {
            deleteSuccessful.value = true;
            deleteErrorMessage.value = null;
            setTimeout(function () {
              logout();
            }, 3000);
          } else {
            deleteSuccessful.value = false;
            deleteErrorMessage.value = deleteError;
          }
        })
        .catch(() => {
          deleteSuccessful.value = false;
          deleteErrorMessage.value = deleteError;
        });
    };

    return {
      oldPassword,
      newPassword,
      confirmPassword,
      passwordErrorMessage,
      changeSuccessful,
      submitPassword,
      cancelErrorMessage,
      cancelSuccessful,
      submitCancel,
      deleteErrorMessage,
      deleteSuccessful,
      submitDelete,
      isPremium
    };
  },
};
</script>
